import React, { useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const plans = [
    { id:1, title: '1-Month', initPrice:'$9.99', price: '$4.99', moneyBack: '14-days', save: '50%',  services: [{ name: 'High-speed, Secure VPN' },{ name: 'Malware and threat protection'},{ name: 'Unlimited traffic' }]},
    { id:2, title: '1-Year', initPrice:'$9.99', price: '$2.49', moneyBack: '30-days', save: '75%', services: [{ name: 'High-speed, Secure VPN' },{ name: 'Malware and threat protection'},{ name: 'Unlimited traffic' }]},
    { id:3, title: '6-Months', initPrice:'$9.99', price: '$3.99', moneyBack: '30-days', save: '60%', services: [{ name: 'High-speed, Secure VPN' },{ name: 'Malware and threat protection'},{ name: 'Unlimited traffic' }]},
  ];

const Pricing = () => {
  const [index, setIndex] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('accessToken');

    if (token) {
      axios.get('https://api.surfheim.com/authentication/', {
          headers: { 'x-access-token': token }
      }).then(response => {
          if (response.status === 200) {
              navigate('/Order/')
          } else {
              navigate('/Sign-in/')
          }}
      ).catch(error => {
          setLoading(false);
          navigate('/Sign-in/')
      });
    } else {
      navigate('/Sign-in/')
    }
  }

  return (
    <section className='section'>
      <div className='container mx-auto'>

        <h1 className='title mb-8 lg:mb-16 text-center font-primary mt-5'>Choose a VPN plan that is ideal for you</h1>

        <div className='flex flex-col lg:flex-row lg:gap-x-[40px] gap-y-[30px] lg:gap-y-0 justify-center items-center'>
          {plans.map((plan, planIndex) => {
            const { title, services, price, initPrice, save} = plan;

            return (
            <div key={planIndex}>

              <div onClick={() => setIndex(planIndex)} 
                className={`${ planIndex === index ? 'bg-light/10 shadow-2xl border-[5px] border-accent' : 'border-[3px] border-grey' } w-[300px] md:w-[360px] rounded-[12px] p-[20px] md:p-[40px] cursor-pointer transition-all`}>
                
                <div className='text-[32px] font-semibold mb-4 md:mb-6 text-center'>{title}</div>


                <div className='flex flex-col md:my-4 my-2 text-center'>

                    <span className='line-through text-dark/55 text-xl'>{initPrice}</span>
                    
                  <div >
                    <span className={`${ planIndex === index ? 'text-4xl text-accent' : 'text-3xl'} font-semibold `}>{price}</span>
                    <span className='text-xl text-dark/55 font-light'> / Month</span>

                  </div>
                  <span className={`${ planIndex === index ? 'text-2xl text-accent font-bold' : 'text-xl text-dark/75 font-semibold'}  mt-4`}>save {save}</span>
                </div>

                <div className='hidden md:flex flex-col gap-y-2 mb-4'>
                  {services.map((service, index) => {
                    const { name } = service;

                      return (
                        <div key={index} className='flex justify-center items-center gap-x-[6px] text-[16px]'>
                          <HiCheck className='text-accent'/>
                          <div>{name}</div>
                        </div>
                      )
                  })}
                </div>


                <div className='flex justify-center items-center pt-3'>
                  <button 
                    onClick={handleSubmit}
                    disabled={loading}
                    className={`${ planIndex ===index ? 'bg-accent hover:bg-accentHover text-white font-semibold' : 'border border-accent text-accent'} btn btn-sm space-x-[14px]`}>
                      <span >Get this plan</span>   
                  </button>
                </div>
                
              </div>
            </div> 
            )
          })}
        </div>

      </div>
    </section>
  )
};

export default Pricing;