import React, { useState, useEffect } from 'react';
import logo from '../../assets/img/logo.png';
import axios from 'axios';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SetUser = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confPassError, setConfPassError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState('');
  
  const siteKey = '0x4AAAAAAAh7yiqQr45zLiE8';

  const usernameRegex = /^[a-zA-Z0-9]{3,32}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const handleUsernameChange = (e) => {
    const usernameValue = e.target.value;
    if (!usernameRegex.test(usernameValue)) {
      setUsernameError('Username must be contain only letters and numbers');
    } else {
      setUsernameError('');
    }
    setUsername(usernameValue);
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    if (!passwordRegex.test(passwordValue)) {
      setPasswordError('Password must be at least 8 characters and contain a special character, capital letter, and number');
    } else {
      setPasswordError('');
    }
    setPassword(passwordValue);
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value;
    if (confirmPasswordValue !== password) {
      setConfPassError('Passwords do not match');
    } else {
      setConfPassError('');
    }
    setConfirmPassword(confirmPasswordValue);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
      const turnstileScript = document.createElement('script');
      turnstileScript.src = `https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback`;
      document.body.appendChild(turnstileScript);
      window.onloadTurnstileCallback = () => {
          window.turnstile.render('#turnstile-container', {
              sitekey: siteKey,
              callback: turnstileCallback,
          });
      };

      const turnstileCallback = (token) => {
          setTurnstileToken(token);
      };

      return () => {
          document.body.removeChild(turnstileScript);
      };
  }, [siteKey]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading || !turnstileToken) return;

    setLoading(true)

    const token = localStorage.getItem('accessTokenVal');

    if (token) {
      axios.defaults.headers.common['x-access-token'] = token;
      axios.post('https://api.surfheim.com/complete-registration/', {
        "username": username,
        "password": password,
        "turnstileToken": turnstileToken
      }, { headers: {"Content-Type": "application/json"}})
      .then(response => {
        if (response.status === 200) {

          const token = response.data.token;
          localStorage.setItem('accessToken', token);

          toast.success('registered successfully', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "colored",
          });

          setTurnstileToken(null);
          setTimeout(() => {
            window.location.href = '/profile/';
          }, 2000);
        } 
      })
      .catch(error => {

        if (error.response.data['message'] === 'Token is not valid' || error.response.data['message'] === 'Token has expired') {
          
          toast.error('Timeout! please try again.', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            theme: "colored",
          });
          
          setTurnstileToken(null);
          setTimeout(() => {
            window.location.href = '/Sign-up/';
          }, 2000);
        } else {

          toast.error(error.response.data['message'], {
            position: "top-center",
            icon: 'error',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setTurnstileToken(null);
          setTimeout(() => {
            window.location.href = '/Sign-up/';
          }, 2000);
        }
      });
    } else {
      toast.error('Error', {
        position: "top-center",
        icon: 'error',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      
      setTurnstileToken(null);
      setLoading(false)
    }};

  return (
    <div className="h-screen w-full flex justify-center items-center flex-col pb-36 md:pb-24 bg-dark">
        <div className="max-w-[320px] min-h-[440px] sm:max-w-[420px] sm:min-h-[540px] bg-active rounded-xl py-4 justify-center items-center px-8 sm:px-12">
            <img src={logo} alt="Logo" className="w-48 sm:w-64 mx-auto mb-2 py-4 sm:py-6" />
            <h1 className="font-bold text-xl sm:text-3xl text-white tracking-tight my-4 sm:pb-4 text-center">Complete Registration</h1>
            <form>
                <div className="mb-2 font-secondary">
                    <label className="block text-light text-sm font-bold mb-1">
                        Username
                    </label>
                    <input
                        className="shadow appearance-none text-gray-700 leading-tight focus:outline-none focus:shadow-outline border rounded-lg w-full align-bottom p-2 sm:p-3 border-accent"
                        id="username"
                        type="text"
                        value={username}
                        onChange={handleUsernameChange}
                        placeholder="Enter username"/>
                    {usernameError && <p className="text-red-500 text-xs" style={{maxWidth:"350px"}}>{usernameError}</p>}
                </div>

                <div className="mb-2 font-secondary">
                    <label className="block text-light text-sm font-bold mb-1">
                        Password
                    </label>
                    <div className="relative">
                        <input
                        className="shadow appearance-none text-gray-700 leading-tight focus:outline-none focus:shadow-outline border rounded-lg w-full align-bottom p-2 sm:p-3 border-accent"
                        id="password"
                        type={showPassword? 'text' : 'password'}
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Enter password"/>
                        <span className="absolute right-0 top-0 mt-3.5 mr-3 cursor-pointer text-[22px]" onClick={handleShowPassword}>
                          {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                        </span>
                    </div>
                    {passwordError && <p className="text-red-500 text-xs max" style={{maxWidth:"350px"}}>{passwordError}</p>}
                </div>

                <div className="mb-2 font-secondary">
                    <label className="block text-light text-sm font-bold mb-1" htmlFor="confirmPassword">
                        Confirm Password
                    </label>
                    <input
                        className="shadow appearance-none text-gray-700 leading-tight focus:outline-none focus:shadow-outline border rounded-lg w-full align-bottom p-2 sm:p-3 border-accent"
                        id="confirmPassword"
                        type={showPassword? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder="Re-enter password"/>
                    {confPassError && <p className="text-red-500 text-xs" style={{maxWidth:"350px"}}>{confPassError}</p>}
                </div>

                <button className="rounded-lg cursor-pointer bg-blue-500 border-blue-500 w-full mt-4 text-white py-2 sm:py-2.5 font-secondary font-semibold"
                    type="submit"
                    disabled={usernameError || passwordError || confPassError || loading}
                    
                    onClick={handleSubmit}>
                    {loading ? 'Loading...' : 'Register'}
                </button>
                <div id="turnstile-container" className="mt-3 items-center"></div>
            </form>
                
        </div>

        <div className="flex flex-col md:flex-row text-white mb-2 text-[14px] font-secondary"
            style={{
            position: 'fixed',
            bottom: '2px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',}}>

            <div className="flex flex-row gap-x-4">
                <div>
                    <a href="/">Home</a>
                </div>
                <div>
                    <a href="/Terms-of-Services/">Term of service</a>
                </div>
                <div>
                    <a href="/Privacy-Policy/">Privacy Policy</a>
                </div>
            </div>
            <div className="mx-0 md:mx-16 lg:mx-36 xl:mx-44">
                support@surfheim.com
            </div>
            <div className="mr-0 md:mr-3">
                Copyright &copy; {new Date().getFullYear()} - All Rights Reserved
            </div>
        </div>

      <ToastContainer />
    </div>
  );
}

export default SetUser;