// App.js
import React, {useState, useEffect} from 'react';
import logo from '../../assets/img/logo.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';


const SignUp = () => {

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const navigate = useNavigate();
  const [turnstileToken, setTurnstileToken] = useState('');

  const siteKey = '0x4AAAAAAAh7yiqQr45zLiE8';

  useEffect(() => {
    const turnstileScript = document.createElement('script');
    turnstileScript.src = `https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback`;
    document.body.appendChild(turnstileScript);
    window.onloadTurnstileCallback = () => {
        window.turnstile.render('#turnstile-container', {
            sitekey: siteKey,
            callback: turnstileCallback,
        });
    };

    const turnstileCallback = (token) => {
        setTurnstileToken(token);
    };

    return () => {
        document.body.removeChild(turnstileScript);
    };
  }, [siteKey]);


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading || !turnstileToken) return;
    
    setLoading(true);

    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email', {
        position: "top-center",
        icon: 'error',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTurnstileToken(null);
      setLoading(false)
      return;
    }

    try {
      const response = await axios.post('https://api.surfheim.com/register-email/', {
        "email": email, 
        "turnstileToken": turnstileToken}, {
          headers: {
              'Content-Type': 'application/json',
          },
        });

      if (response.status === 200) {

        toast.success('Verification code is sent to your email', {
          position: "top-center",
          icon: 'check',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTurnstileToken(null);
        setTimeout(() => {
          navigate('/Verify-email/', {state: { email }});
        }, 2000);
      }

    } catch (error) {
      toast.error(error.response.data['message'], {
        position: "top-center",
        icon: 'error',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTurnstileToken(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-screen w-full flex justify-center items-center flex-col pb-36 md:pb-24 bg-dark">
      <Helmet>

        <title>Sign Up | Surfheim VPN - Join for Online Privacy & Security with no-logs Policy</title>
        <link rel="canonical" href="https://www.surfheim.com/Sign-up/" />
        <meta name="description" content="Create your Surfheim VPN account to enjoy secure browsing, military-grade encryption, and a strict no-logs policy. Join us for enhanced online privacy." />
        <meta name="keywords" content="sign up, create account, Surfheim VPN registration, secure browsing, online privacy, no logs policy" />
        <meta property="og:title" content="Join Surfheim VPN - Sign Up for Enhanced Online Privacy" />
        <meta property="og:description" content="Sign up for Surfheim VPN to access fast, secure, and reliable VPN services. Enjoy military-grade encryption and a strict no-logs policy." />
        <meta property="og:image" content="%PUBLIC_URL%/sign-up-image.png" />
        <meta property="og:url" content="https://www.surfheim.com/Sign-up/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="max-w-[320px] min-h-[440px] sm:max-w-[380px] sm:min-h-[500px] bg-active rounded-xl py-4 justify-center items-center px-8 sm:px-12">
        <img src={logo} alt="Logo" className="w-64 mx-auto mb-2 py-6" />
        <h1 className="font-semibold text-2xl sm:text-3xl text-white tracking-tight my-4 sm:pb-4 text-center">Create an account</h1>
        <p className="text-md text-center text-white mb-4 font-secondary">Already have an account? <a href='/Sign-in/' className="text-blue-500">Log in</a></p>

        <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            className="border rounded-lg w-full align-bottom p-2 sm:p-3 border-active font-secondary"
            placeholder="Enter Email Address"
        />

        <button
            onClick={handleSubmit}
            disabled={loading}
            className="rounded-lg cursor-pointer bg-blue-500 border-blue-500 w-full mt-4 text-white py-2 sm:py-2.5 font-secondary font-semibold">
            {loading ? 'Loading...' : 'Continue'}
        </button>

        <div id="turnstile-container" className="mt-3 items-center"></div>

        <p className="text-sm text-white mt-10 text-center font-secondary text-[16px] " >By registering you agree to our <a href='/Terms-of-service/' className='font-semibold underline'>Terms of use</a> and <a href='/Privacy-policy/' className='font-semibold underline'>Privacy Policy</a>.</p>
      </div>
          
      <div className="flex flex-col md:flex-row text-white mb-2 text-[14px] font-secondary"
        style={{ position: 'fixed', bottom: '2px', display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
          <div className="flex flex-row gap-x-4">
            <div>
                <a href="/">Home</a>
            </div>
            <div>
                <a href="/Terms-of-Services/">Term of service</a>
            </div>
            <div>
                <a href="/Privacy-Policy/">Privacy Policy</a>
            </div>
          </div>
          <div className="mx-0 md:mx-16 lg:mx-36 xl:mx-44">
              support@surfheim.com
          </div>
          <div className="mr-0 md:mr-3">
              Copyright &copy; {new Date().getFullYear()} - All Rights Reserved
          </div>
      </div>

        <ToastContainer />
    </div>
  );
};
          
export default SignUp;