import React, { useEffect, useState } from 'react';

const Consent = ({ onConsent }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem('consentGiven');
    if (!consentGiven) {
      setIsVisible(true);
    }
  }, []);

  const handleConsent = () => {
    localStorage.setItem('consentGiven', 'true');
    setIsVisible(false);
    onConsent(); // Call the function to start Google Analytics
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 flex justify-between items-center text-justify">
      <span className='font-secondary mr-6 md:mr-0'>This website uses cookies to enhance the user experience.</span>
      <button onClick={handleConsent} className="btn btn-sm lg:btn-md btn-accent flex justify-center items-center lg:gap-x-4">
        OK
      </button>
    </div>
  );
};

export default Consent;