import React, { useState, useEffect } from 'react';
import logo from '../../assets/img/logo.png';
import axios from 'axios';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';



const SignIn = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [turnstileToken, setTurnstileToken] = useState('');

    const siteKey = '0x4AAAAAAAh7yiqQr45zLiE8';


    useEffect(() => {
        const turnstileScript = document.createElement('script');
        turnstileScript.src = `https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback`;
        document.body.appendChild(turnstileScript);
        window.onloadTurnstileCallback = () => {
            window.turnstile.render('#turnstile-container', {
                sitekey: siteKey,
                callback: turnstileCallback,
            });
        };

        const turnstileCallback = (token) => {
            setTurnstileToken(token);
        };

        return () => {
            document.body.removeChild(turnstileScript);
        };
    }, [siteKey]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading || !turnstileToken) return;

        setLoading(true);

        try {
            const response = await axios.post('https://api.surfheim.com/login/', {
                username,
                password,
                turnstileToken,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                const token = response.data.token;
                localStorage.setItem('accessToken', token);

                toast.success('Logged in successfully!', {
                    position: 'top-center',
                    icon: 'check',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });

                setTurnstileToken(null);

                setTimeout(() => {
                    window.location.href = '/profile/';
                }, 1000);
            }
        } catch (error) {
            toast.error(error.response.data, {
                position: 'top-center',
                icon: 'error',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            setTurnstileToken(null);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-dark">
            <Helmet>
                <title>Sign In | Surfheim VPN - Secure Your Online Privacy</title>
                <link rel="canonical" href="https://www.surfheim.com/Sign-in/" />
                <meta name="description" content="Sign in to your Surfheim VPN account to access secure browsing and online privacy features. Enjoy fast connections and a strict no-logs policy." />
                <meta name="keywords" content="sign in, Surfheim VPN login, VPN account, secure browsing, online privacy, no logs policy" />
                <meta property="og:title" content="Sign In to Your Surfheim VPN Account" />
                <meta property="og:description" content="Access your Surfheim VPN account to enjoy secure browsing and online privacy. Sign in now for fast and reliable VPN services." />
                <meta property="og:image" content="%PUBLIC_URL%/sign-in-image.png" />
                <meta property="og:url" content="https://www.surfheim.com/Sign-in/" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className="h-screen w-full flex justify-center items-center flex-col pb-36 md:pb-24">
                <div className="max-w-[320px] min-h-[440px] sm:max-w-[440px] sm:min-h-[540px] bg-active rounded-xl py-4 justify-center items-center px-8 sm:px-12">
                    <img src={logo} alt="Logo" className="w-48 sm:w-64 mx-auto mb-2 py-4 sm:py-6" />
                    <h1 className="font-semibold text-2xl sm:text-4xl text-white tracking-tight my-4 sm:pb-4 text-center">Sign in</h1>
                    <p className="text-md text-center text-white mb-4 font-secondary">Don't have an account? <a href="/Sign-up/" className="text-blue-500">Sign up</a></p>

                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="border rounded-lg w-full align-bottom p-2 sm:p-3 border-active font-secondary"
                        placeholder="Enter Email or Username"
                    />

                    <div className="relative mt-2.5 font-secondary">
                        <input
                            type={showPassword ? 'text' : 'password'} 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="border rounded-lg w-full align-bottom p-2 sm:p-3 border-active pr-24" 
                            placeholder="Enter Password"
                        />

                        <span 
                            onClick={() => setShowPassword(!showPassword)} 
                            className="absolute right-4 py-3 cursor-pointer text-[22px]">
                            {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                        </span>
                    </div>
                       
                    <button
                        onClick={handleSubmit}
                        disabled={loading}
                        className="rounded-lg cursor-pointer bg-blue-500 border-blue-500 w-full mt-4 text-white py-2 sm:py-2.5 font-secondary font-semibold">

                    {loading ? 'Loading...' : 'Sign in'}
                    </button>

                    <div id="turnstile-container" className="mt-3 items-center"></div>
                        
                    <p className="text-md text-blue-400 mt-4 sm:mt-10 text-center font-secondary">
                        <a href="/Forget-Password/">Forget your password?</a>
                    </p>
                </div>
    
                <div
                    className="flex flex-col md:flex-row text-white mb-2 text-[14px] font-secondary"
                    style={{position: 'fixed', bottom: '2px', display: 'flex', justifyContent: 'center', alignItems: 'center',}}>

                    <div className="flex flex-row gap-x-4">
                        <div>
                            <a href="/">Home</a>
                        </div>
                        <div>
                            <a href="/Terms-of-Services/">Term of service</a>
                        </div>
                        <div>
                            <a href="/Privacy-Policy/">Privacy Policy</a>
                        </div>
                    </div>

                    <div className="mx-0 md:mx-16 lg:mx-36 xl:mx-44">
                        support@surfheim.com
                    </div>
                    
                    <div className="mr-0 md:mr-3">
                        Copyright &copy; {new Date().getFullYear()} - All Rights Reserved
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
        );
    };
    
    export default SignIn;