import React from "react";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home/Home';
import SignIn from "./pages/Sign/SignIn";
import SignUp from "./pages/Sign/SignUp";
import ForgetPass from "./pages/Sign/ForgetPass";
import ChangePassword from "./pages/Sign/ChangePass";
import Verify from "./pages/Sign/Verify";
import SetUser from "./pages/Sign/SetUser";
import Profile from "./pages/User/Profile";
import Ticketing from "./pages/User/Ticketing";
import Cookies from "./pages/Legal/Cookies";
import Privacy from "./pages/Legal/Privacy";
import Refund from "./pages/Legal/Refund";
import Terms from "./pages/Legal/Terms";
import AboutUS from "./pages/AboutUs";
import Features from "./pages/Features";
import Download from "./pages/User/Download";
import UserGuide from "./pages/User/UserGuide";
import PricingPage from "./pages/Order/PricingPage";
import Order from "./pages/Order/Order";
import SuccessPay from "./pages/Order/SuccessPay";
import FaildPay from "./pages/Order/FailedPay";
import Tracking from "./Tracking";
import Consent from "./pages/Consent";


const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <Tracking /> 
        <Home />
      </>
      )
  },
  {
    path: '/Pricing/',
    element: (
      <>
        <Tracking /> 
        <PricingPage />
      </>
      )
  },
  {
    path: '/Order/',
    element: (
      <>
        <Tracking /> 
        <Order />
      </>
      )
  },
  {
    path: '/Sign-in/',
    element: (
      <>
        <Tracking /> 
        <SignIn />
      </>
      )
  },
  {
    path: '/Sign-up/',
    element: (
      <>
        <Tracking /> 
        <SignUp />
      </>
      )
  },
  {
    path: '/Forget-Password/',
    element: (
      <>
        <Tracking /> 
        <ForgetPass />
      </>
      )
  },
  {
    path: '/Change-Password/',
    element: <ChangePassword />
  },
  {
    path: '/Verify-email/',
    element: <Verify />
  },
  {
    path: '/Complete-registration/',
    element: <SetUser/>
  },
  {
    path: '/Profile/',
    element: (
      <>
        <Tracking /> 
        <Profile />
      </>
      )
  },
  {
    path: '/Ticket/',
    element: (
      <>
        <Tracking /> 
        <Ticketing />
      </>
      )
  },
  {
    path: '/Terms-of-service/',
    element: (
      <>
        <Tracking /> 
        <Terms />
      </>
      )
  },
  {
    path: '/Privacy-policy/',
    element: (
      <>
        <Tracking /> 
        <Privacy />
      </>
      )
  },
  {
    path: '/Cookies-and-preferences/',
    element: (
      <>
        <Tracking /> 
        <Cookies />
      </>
      )
  },
  {
    path: '/Refund-policy/',
    element: (
      <>
        <Tracking /> 
        <Refund />
      </>
      )
  },
  {
    path: '/About-us/',
    element: (
      <>
        <Tracking /> 
        <AboutUS />
      </>
      )
  },
  {
    path: '/Features/',
    element: (
      <>
        <Tracking /> 
        <Features />
      </>
      )
  },
  {
    path: '/Download/',
    element: (
      <>
        <Tracking /> 
        <Download />
      </>
      )
  },
  {
    path: '/User-Guide/',
    element: (
      <>
        <Tracking /> 
        <UserGuide />
      </>
      )
  },
  {
    path: '/Successful-payment',
    element: <SuccessPay />
  },
  {
    path: '/Failed-payment/',
    element: <FaildPay />
  },
])

const App = () => {
  const handleConsent = () => {
  };
 
  return (
    <div>

      <RouterProvider router={router} />
      <Consent onConsent={handleConsent} />

    </div>
  );
};

export default App;
