import React from 'react';
import { FaWindows, FaLinux, FaAndroid, FaApple } from "react-icons/fa";
import { RiMacbookLine } from "react-icons/ri";
import Header from '../Header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';


const Download = () => {

  return (
    <div>
        <Helmet>
            <title>Download Applications & Software | Surfheim VPN</title>
            <link rel="canonical" href="https://www.surfheim.com/Download/" />
            <meta name="description" content="Download Surfheim VPN applications and software for various devices including Windows, macOS, Android, and iOS. Enjoy secure browsing and online privacy with our easy-to-use VPN solutions." />
            <meta name="keywords" content="download VPN, Surfheim VPN download, VPN software, Windows VPN, macOS VPN, Android VPN, iOS VPN, secure browsing, online privacy" />
            <meta property="og:title" content="Download Surfheim VPN Applications for All Devices" />
            <meta property="og:description" content="Get the latest Surfheim VPN applications for Windows, macOS, Android, and iOS. Experience fast, secure, and reliable VPN services with our user-friendly software." />
            <meta property="og:image" content="%PUBLIC_URL%/download-image.png" />
            <meta property="og:url" content="https://www.surfheim.com/Download/" />
            <meta property="og:type" content="website" />
        </Helmet>

        <Header />

        <div>
            <h1 className="text-2xl md:text-4xl font-semibold mt-8 text-center">Download the Surfheim VPN app for all your devices</h1>
            <p className="text-gray-700 text-center my-4">To use the Surfheim VPN service, download the app compatible with your device and follow the instructions provided in the <a href='/User-Guide/' className='text-blue-500 underline'>user guide</a> .</p>
        </div>
        
        <div className="md:container grid grid-cols-1 md:grid-cols-2 bg-light/25 mx-8 md:mx-auto px-4 py-8 rounded-xl my-8 text-justify font-secondary">
            
            <div>
                <div className='flex flex-row text-center pt-8 items-center gap-x-4'>
                    <h1 className='text-xl font-semibold'>Android</h1>
                    <FaAndroid className='text-3xl' />
                </div>
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>v2RayTun:<a className='pl-1 text-lg text-blue-500' href='https://play.google.com/store/apps/details?id=com.v2raytun.android&hl=en'>
                        Google Play</a>
                    </h2>
                </div>
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>v2RayNG:<a className='pl-1 text-lg text-blue-500' href='https://play.google.com/store/apps/details?id=com.v2ray.ang&hl=en&pli=1'>
                        Google Play /
                        </a>
                        <a className='pl-1 text-lg text-blue-500' href='https://github.com/2dust/v2rayNG'>
                            Github
                        </a>
                    </h2>
                </div>
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>NekoBox:<a className='pl-1 text-lg text-blue-500' href='https://play.google.com/store/apps/details?id=moe.nb4a&hl=en'>
                        Google Play</a>
                    </h2>
                </div>
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>ARMod:<a className='pl-1 text-lg text-blue-500' href='https://play.google.com/store/apps/details?id=com.artunnel57&hl=en'>
                        Google Play</a>
                    </h2>
                </div>
                
            </div>

            <div >
                <div className='flex flex-row text-center pt-8 items-center gap-x-4'>
                    <h1 className='text-xl font-semibold'>iPhone</h1>
                    <FaApple className='text-3xl' />
                </div>

                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>Streisand:<a className='pl-1 text-lg text-blue-500' href='https://apps.apple.com/us/app/streisand/id6450534064'>
                            App Store
                        </a>
                    </h2>
                </div>

                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>FoxRay:<a className='pl-1 text-lg text-blue-500' href='https://apps.apple.com/us/app/foxray/id6448898396'>
                            App Store
                        </a>
                    </h2>
                </div>

                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>Happ: <a className='pl-1 text-lg text-blue-500' href='https://apps.apple.com/us/app/happ-proxy-utility/id6504287215'>
                            App Store
                        </a>
                    </h2>
                </div>   

                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>V2Box: <a className='pl-1 text-lg text-blue-500' href='https://apps.apple.com/us/app/v2box-v2ray-client/id6446814690'>
                            App Store
                        </a>
                    </h2>
                </div>          
            </div>

            <div >
                <div className='flex flex-row text-center pt-8 items-center gap-x-4'>
                    <h1 className='text-xl font-semibold'>Windows</h1>
                    <FaWindows className='text-3xl' />
                </div>
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>V2RayN: <a className='pl-1 text-lg text-blue-500' href='https://github.com/2dust/v2rayN'>
                        Github
                        </a>
                    </h2>
                </div>
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>Nekoray: <a className='pl-1 text-lg text-blue-500' href='https://github.com/MatsuriDayo/nekoray'>
                        Github
                        </a>  
                    </h2>
                </div>
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>Clash Verge: <a className='pl-1 text-lg text-blue-500' href='https://clashverge.net/en/'>
                        Website
                        </a>
                    </h2>
                </div>
            </div>

            <div >
                <div className='flex flex-row text-center pt-8 items-center gap-x-4'>
                    <h1 className='text-xl font-semibold'>Linux</h1>
                    <FaLinux className='text-3xl' />
                </div>
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>V2RayN: <a className='pl-1 text-lg text-blue-500' href='https://github.com/2dust/v2rayN'>
                        Github
                        </a>
                    </h2>
                </div>
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>Nekoray: <a className='pl-1 text-lg text-blue-500' href='https://github.com/MatsuriDayo/nekoray'>
                        Github
                        </a>
                    </h2>
                </div> 
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>Clash Verge: <a className='pl-1 text-lg text-blue-500' href='https://clashverge.net/en/'>
                        Website
                        </a>
                    </h2>
                </div>               
            </div>

            <div >
                <div className='flex flex-row text-center pt-8 items-center gap-x-4'>
                    <h1 className='text-xl font-semibold'>MacOS</h1>
                    <RiMacbookLine className='text-3xl' />
                </div>
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>FoxRay:<a className='pl-1 text-lg text-blue-500' href='https://apps.apple.com/us/app/foxray/id6448898396'>
                            App Store
                        </a>
                    </h2>
                </div>
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>V2RayXS: <a className='pl-1 text-lg text-blue-500' href='https://github.com/tzmax/V2RayXS'>
                         Github
                        </a>
                    </h2>
                </div>
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>Nekoray:
                        <a className='pl-1 text-lg text-blue-500' href='https://github.com/MatsuriDayo/nekoray'>
                        Github</a>
                    </h2>
                </div>
                <div className='flex flex-row pt-3 items-center'>
                    <h2 className='text-xl pl-6'>Clash Verge: <a className='pl-1 text-lg text-blue-500' href='https://clashverge.net/en/'>
                        Website
                        </a>
                    </h2>
                </div>                
            </div>

        </div>

        


        <Footer />
    </div>
  );
};

export default Download;