import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';


const Terms = () => {
  return (
    <div className=''>

      <Helmet>
        <title>Terms of Service | Surfheim VPN - Your Privacy Matters</title>
        <link rel="canonical" href="https://www.surfheim.com/terms-of-service/" />
        <meta name="description" content="Read the Terms of Service for Surfheim VPN. Understand your rights and responsibilities while using our services, including our commitment to your privacy and security." />
        <meta name="keywords" content="Terms of Service, Surfheim VPN, user agreement, privacy policy, online security, VPN terms" />
        <meta property="og:title" content="Surfheim VPN Terms of Service - Your Privacy Matters" />
        <meta property="og:description" content="Familiarize yourself with the Terms of Service for Surfheim VPN. Learn about your rights, our privacy commitments, and how we protect your online experience." />
        <meta property="og:image" content="%PUBLIC_URL%/terms-image.png" />
        <meta property="og:url" content="https://www.surfheim.com/terms-of-service/" />
        <meta property="og:type" content="website" />
      </Helmet>
      
      <Header />

      <div className="md:container bg-light/25 mx-8 md:mx-auto px-4 py-8 rounded-xl my-8 text-justify font-secondary">
        <h1 className="text-4xl font-semibold mb-8 text-center">Terms of Services</h1>
        
        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p className="text-gray-700 mb-8 ml-4">
          hese Terms of Service govern your use of our surfheim VPN services. By accessing or using our services, you agree to comply with these terms. If you do not agree, please refrain from using our services.
        </p>
        
        <h2 className="text-2xl font-semibold mb-4">2. Eligibility</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          To use our services, you must be at least 18 years old or have the consent of a parent or guardian. By using our services, you represent that you meet these eligibility requirements.
        </p>
        
        <h2 className="text-2xl font-semibold mb-4">3. Account Registration</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          You may need to create an account to access certain features of our service. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
        </p>
        
        <h2 className="text-2xl font-semibold mb-4">4. Services Provided</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          Our VPN service provides secure and private internet access by encrypting your online traffic and masking your IP address. We may offer additional features such as ad blocking, malware protection, and access to geo-restricted content.
        </p>
        
        <h2 className="text-2xl font-semibold mb-4">5. User Responsibilities </h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          You agree to use the service only for lawful purposes and in accordance with applicable laws and regulations. You are prohibited from using the service to engage in any illegal activity, including but not limited to copyright infringement, hacking, or distributing malware.
        </p>

        <h2 className="text-2xl font-semibold mb-4">6. Payment Termsn</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          By subscribing to our service, you agree to pay all applicable fees associated with your chosen plan. Payments will be processed through a secure payment gateway, and you authorize us to charge your payment method for recurring fees until you cancel your subscription.
        </p>
        
        <h2 className="text-2xl font-semibold mb-4">7. Cancellation and Refund Policy</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          You may cancel your subscription at any time through your account settings. Refunds may be available under certain conditions as outlined in our refund policy.
        </p>

        <h2 className="text-2xl font-semibold mb-4">8. Privacy Policy</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          Your privacy is important to us. Our Privacy Policy outlines how we collect, use, and protect your personal information while using our services.
        </p>

        <h2 className="text-2xl font-semibold mb-4">9. Limitation of Liability</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          We strive to provide reliable services; however, we cannot guarantee uninterrupted access or that the service will be free from errors or security breaches. Our liability is limited to the maximum extent permitted by law.
        </p>

        <h2 className="text-2xl font-semibold mb-4">10. Indemnification</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          You agree to indemnify and hold harmless the company from any claims arising out of your use of the service or violation of these terms.
        </p>

        <h2 className="text-2xl font-semibold mb-4">11. Changes to Terms</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          We reserve the right to modify these Terms of Service at any time without prior notice. Your continued use of the service after changes constitutes acceptance of the new terms.
        </p>

        <h2 className="text-2xl font-semibold mb-4">12. Governing Law</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          These terms shall be governed by and construed in accordance with the laws applicable in the jurisdiction where we operate.
        </p>

        <h2 className="text-2xl font-semibold mb-4">13. Geographic Restrictions</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          Our VPN service is not available to users located in certain countries, including but not limited to China, Iran, North Korea, and Cuba. If you attempt to access or purchase our services from these regions, we reserve the right to terminate your account immediately without a refund. By using our service, you acknowledge and accept that we may take such actions to comply with applicable laws and regulations.
        </p>

        <h2 className="text-2xl font-semibold mb-4">14. Abuse and Illegal Activities</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          We maintain a strict policy against the use of our VPN service for illegal activities, including but not limited to cyberattacks, hacking, or any form of abuse. If we determine that your account is being used for such purposes, we reserve the right to terminate your subscription without notice. Furthermore, we may disclose your information to law enforcement agencies and anti-crime organizations as required by law.
        </p>

        <h2 className="text-2xl font-semibold mb-4">15. Bandwidth and Data Limitations</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
        Our VPN service offers unlimited device connections and bandwidth for our users. However, to ensure fair usage and maintain optimal service quality, we impose a data limit of 1TB per month. If your data usage exceeds this limit, we reserve the right to impose restrictions on your account, which may include throttling your connection speed or limiting access to certain features. By using our service, you agree to adhere to these data usage policies.
        </p>

        <h2 className="text-2xl font-semibold mb-4">16. Contact Information</h2>
        
        <p className="text-gray-700 mb-8 ml-4">
          For questions regarding these Terms of Service or other inquiries, please contact us through our support channels provided on our website.
        </p>
      </div>

      <Footer />

  </div>
  )
};

export default Terms;