import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';


const Cookies = () => {
    return (
        <div>
            <Helmet>
                <title>Cookies and Preferences | Surfheim VPN - Your Privacy Matters</title>
                <link rel="canonical" href="https://www.surfheim.com/Cookies-and-preferences/" />
                <meta name="description" content="Learn about Surfheim VPN's Cookies and Preferences policy. Understand how we use cookies to enhance your experience and manage your privacy settings." />
                <meta name="keywords" content="cookies policy, Surfheim VPN, user preferences, online privacy, cookie usage, privacy settings" />
                <meta property="og:title" content="Surfheim VPN Cookies and Preferences Policy" />
                <meta property="og:description" content="Discover how Surfheim VPN uses cookies to improve your browsing experience and how you can manage your preferences for enhanced privacy." />
                <meta property="og:image" content="%PUBLIC_URL%/cookies-image.png" />
                <meta property="og:url" content="https://www.surfheim.com/Cookies-and-preferences/" />
                <meta property="og:type" content="website" />
            </Helmet>

            <Header />
            <div className="md:container bg-light/25 mx-8 md:mx-auto px-4 py-8 rounded-xl my-8 text-justify font-secondary">
                <h1 className="text-4xl font-semibold mb-8 text-center">
                Cookies and Preferences for Surfheim VPN
                </h1>
                <p className="text-gray-700 mb-8">
                    When creating a cookies and preferences policy for a VPN website, it is essential to ensure that users are informed about the types of cookies used, their purposes, and how users can manage their preferences. Below is a comprehensive outline based on the content from the specified web pages.
                </p>

                <h2 className="text-2xl font-semibold mb-4">1. Introduction to Cookies</h2>
                <p className="text-gray-700 mb-8 ml-4">
                    Cookies are small text files stored on your device when you visit a website. They help enhance user experience by remembering preferences, login information, and other settings. For VPN services, cookies also play a role in tracking usage patterns to improve service delivery.
                </p>

                <h2 className="text-2xl font-semibold mb-4">2. Types of Cookies Used</h2>
                <div className="text-gray-700 mb-8">
                    <p className='ml-4'> <span className='font-semibold text-gray-900'>- Essential Cookies:</span> {"These cookies are necessary for the website’s functionality. Without them, certain features may not work properly. For example, they might be used to maintain user sessions or enable secure logins."}</p>
                    <p className='ml-4'> <span className='font-semibold text-gray-900'>- Performance Cookies:</span> {"These cookies collect information about how visitors use the site, such as which pages are visited most often and if users receive error messages from web pages. This data helps improve the website’s performance."}</p> 
                    <p className='ml-4'> <span className='font-semibold text-gray-900'>- Functional Cookies:</span> {"These allow the website to remember choices you make (such as your username or language preference) and provide enhanced features. They may also be used to provide services you have requested."}</p> 
                    <p className='ml-4'> <span className='font-semibold text-gray-900'>- Targeting/Advertising Cookies:</span> {"These cookies are used to deliver advertisements more relevant to you and your interests. They can also limit how many times you see an advertisement and help measure the effectiveness of advertising campaigns."}</p>  
                </div>

                <h2 className="text-2xl font-semibold mb-4">3. Purpose of Using Cookies</h2>
                <p className="text-gray-700 mb-8 ml-4">
                    <p className='font-semibold mb-2 text-gray-900'>The primary purposes of using cookies on a VPN website include:</p><p></p>
                    <p>- Enhancing user experience by personalizing content.</p>
                    <p>- Analyzing site traffic and user behavior for optimization.</p>
                    <p>- Ensuring security during user sessions.</p>
                    <p>- Delivering targeted advertisements based on user interests.</p>
                </p>
                
                <h2 className="text-2xl font-semibold mb-4">4. User Consent and Management of Preferences</h2>
                <div className="text-gray-700 mb-8">
                    <p className='font-semibold ml-4 mb-2 text-gray-900'> Users should be informed that they have control over cookie management:</p>
                    <p className='ml-4'> <span className='font-semibold text-gray-900'>- Consent Banner:</span> {"Upon visiting the site, users should see a cookie consent banner that explains what cookies are being used and provides options to accept or decline non-essential cookies."}</p>
                    <p className='ml-4'> <span className='font-semibold text-gray-900'>- Cookie Settings:</span> {"Users should have access to a dedicated section where they can manage their cookie preferences at any time after their initial consent."}</p>
                    <p className='ml-4'> <span className='font-semibold text-gray-900'>- Opt-Out Options:</span> {"Clear instructions should be provided on how users can opt-out of specific types of cookies through browser settings or third-party tools."}</p>                     
                </div>

                <h2 className="text-2xl font-semibold mb-4">5. Privacy Considerations</h2>
                <div className="text-gray-700  mb-8 ml-4">
                    <p className='font-semibold text-gray-900 mb-2'>Given that VPN services deal with sensitive data, it is crucial to assure users that:</p>
                    <p>{"- No personally identifiable information (PII) will be collected without explicit consent."}</p>
                    <p>{"- Data collected through cookies will not be shared with third parties without user permission."}</p>
                    <p>{"- Users will be informed about any changes in cookie policies through updates on the website."}</p>             
                    
                </div>

                <h2 className="text-2xl font-semibold mb-4 mt-8">6. Conclusion</h2>
                <div className="text-gray-700 ml-4">
                    A well-defined cookie policy enhances transparency between VPN providers and their users while ensuring compliance with privacy regulations such as GDPR or CCPA. By clearly outlining the types of cookies used, their purposes, and providing options for managing preferences, VPN websites can build trust with their customers.
                </div>
            </div>

            <Footer />
        </div>
    )
};

export default Cookies;
