import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Tracking = () => {
  const location = useLocation();

  useEffect(() => {
    const consentGiven = localStorage.getItem('consentGiven');
    if (consentGiven) {
      window.gtag('config', 'G-QEVNTN0BYG', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return null;
};

export default Tracking;