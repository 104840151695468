import React from 'react';
import img1 from '../../../assets/img/feature1-img.png';

const Feature1 = () => {


  return (
    <section className='section'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row lg:items-start lg:gap-x-[30px]'>

          <div className='flex-1' >
            <h1 className='pretitle'>Reliable Connectivity</h1>
            <h2 className='title'>Worldwide Fast and Secure Access </h2>
            <p className='lead text-justify'>Our VPN service guarantees reliable connectivity with ultra-fast servers located around the globe. Enjoy seamless browsing and streaming without interruptions, ensuring that your internet experience is both efficient and enjoyable. </p>
            <a href='/Features/'>
              <button className='btn-link flex items-center gap-x-3 hover:gap-x-5 transition-all'>
                Learn more  
              </button>
            </a>

          </div>
          
          <div className='flex-1'>
            <img src={img1} alt='Surfheim VPN guarantees reliable connectivity with ultra-fast servers located around the globe with no-logs policy'/>
          </div>

        </div>
      </div>
    </section>
  )
};

export default Feature1;