import React, { useState, useEffect } from 'react';
import logo from '../assets/img/logo.png';
import { FaUserCircle, FaChevronDown  } from "react-icons/fa";
import axios from 'axios';


const Header = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);


    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            axios.get('https://api.surfheim.com/authentication/', {
                headers: { 'x-access-token': token }
            }).then(response => {
                if (response.status === 200) {
                    setIsLoggedIn(true);
                } else {
                    setIsLoggedIn(false);
                }}
            ).catch(error => {
                setIsLoggedIn(false);
            });
        }
    }, []);


    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        setIsLoggedIn(false);
        window.location.href = '/';
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className='pt-2 w-full bg-white'>
            <div className='flex flex-row justify-between items-center'>
                <div className='flex flex-row items-center justify-between'>
                    <a href='/' >
                        <img className='w-40 sm:w-48 lg:w-64 ml-2 lg:ml-10' src={logo} alt='Surfheim VPN Logo - Best, fast, very affordable, cheap and highly recommended VPN service.'/>
                    </a>
                    <div className='hidden md:flex flex-row gap-x-4 mt-1 ml-6 lg:ml-20 text-[17px] font-secondary'>
                        <a href='/' className='text-accentHover font-semibold'>Home</a>
                        <a href='/Pricing/' className='text-accentHover font-semibold'>Pricing</a>
                        <a href='/Download/' className='text-accentHover font-semibold'>Download</a>
                        <a href='/User-Guide/' className='text-accentHover font-semibold'>User Guide</a>
                    </div>
                </div>
                
                <div className='flex flex-row' >
                    {isLoggedIn ? (
                        <div className="flex items-center font-secondary font-semibold mr-2 gap-x-1.5 sm:mr-6 sm:gap-x-4 text-[14px] sm:text-[17px]">
                            <button onClick={handleLogout} className=" border py-1 px-1 sm:px-1.5 rounded-lg border-accent text-accentHover shadow-md hover:shadow-xl">
                                Sign Out
                            </button>
                            <a className='text-[32px] text-accentHover' href='/Profile/'>
                                <FaUserCircle />
                            </a>
                        </div>
                    ) : (
                        <div className="flex items-center justify-center font-secondary font-semibold gap-x-1.5 mr-1 lg:gap-x-2.5 lg:mr-4 text-[15px] lg:text-[17px]">
                            <a href='/Sign-in/' className='border py-0.5 md:py-1 px-1.5 lg:px-2 rounded-lg border-accent text-accentHover shadow-md hover:shadow-xl'>
                                Sign In
                            </a>
                            <a href='/Sign-up/' className='border py-0.5 md:py-1 px-1.5 lg:px-2 rounded-lg bg-accentHover text-white shadow-md hover:shadow-xl '>
                                Sign Up
                            </a>
                        </div>
                    )}
                </div>
            </div>
            
            
            <div className='flex flex-col items-center justify-center'>
            
                <button className='md:hidden text-accentHover' onClick={toggleMenu}>
                    <FaChevronDown size={20} />
                </button>
                {isMenuOpen && (
                <div className='md:hidden flex flex-col gap-y-2 text-[16px] font-secondary items-center'>
                    <a href='/' className='text-accentHover font-semibold'>Home</a>
                    <a href='/Pricing/' className='text-accentHover font-semibold'>Pricing</a>
                    <a href='/Downloads/' className='text-accentHover font-semibold'>Download</a>
                    <a href='/User-Guide/' className='text-accentHover font-semibold'>User Guide</a>
                </div>
            )}
            </div>

            <hr className='mt-2'/>
            
        </div>
    );
};


export default Header;