import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';


const Refund = () => {
    return (
        <div>
            <Helmet>
                <title>Refund Policy | Surfheim VPN - Hassle-Free Money-Back Guarantee</title>
                <link rel="canonical" href="https://www.surfheim.com/Refund-policy/" />
                <meta name="description" content="Review Surfheim VPN's Refund Policy. Learn about our hassle-free money-back guarantee and the conditions under which refunds are issued." />
                <meta name="keywords" content="refund policy, Surfheim VPN, money-back guarantee, customer satisfaction, refund conditions" />
                <meta property="og:title" content="Surfheim VPN Refund Policy - Hassle-Free Money-Back Guarantee" />
                <meta property="og:description" content="Understand the terms of Surfheim VPN's Refund Policy. We offer a hassle-free money-back guarantee to ensure your satisfaction with our services." />
                <meta property="og:image" content="%PUBLIC_URL%/refund-image.png" />
                <meta property="og:url" content="https://www.surfheim.com/Refund-policy/" />
                <meta property="og:type" content="website" />
            </Helmet>

            <Header />

            <div className="md:container bg-light/25 mx-8 md:mx-auto px-4 py-8 rounded-xl my-8 text-justify font-secondary">
                <h1 className="text-4xl font-semibold mb-8 text-center">
                {"Refund Policy for Service (Money Back Guarantee)"}
                </h1>


                <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
                <div className="text-gray-700 mb-8 ml-4">
                    {"At Surfheim, we are committed to providing high-quality services that meet your expectations. We understand that sometimes things may not go as planned, which is why we offer money back guarantee on our services."}
                </div>

                <h2 className="text-2xl font-semibold mb-4">Eligibility for Refunds</h2>
                <div className="text-gray-700 mb-8 ml-4">
                    <p className='font-bold mb-2'>To be eligible for a refund under our 30-day money back guarantee, you must meet the following criteria:</p>
                    <p><span className='font-semibold text-gray-900'>- </span>{" You must request a refund within the specified time period of the service being rendered."}</p>
                    <p><span className='font-semibold text-gray-900'>- </span>{" You must provide proof of purchase, such as an invoice or receipt."}</p>
                    <p><span className='font-semibold text-gray-900'>- </span>{" The request must be made through our designated customer service channels"}</p>
                </div>
                
                <h2 className="text-2xl font-semibold mb-4">Conditions for Refunds</h2>
                <div className="text-gray-700 mb-8 ml-4">
                    <p className='mb-2'>Refunds will only be issued if:</p>
                    <p><span className='font-semibold text-gray-900'>-</span>{" You have utilized the service and are dissatisfied due to reasons such as unmet expectations or service quality issues."}</p>
                    <p><span className='font-semibold text-gray-900'>-</span>{" You have provided us with feedback regarding your experience and given us an opportunity to resolve any issues before requesting a refund."}</p>
                    <p className='mb-2 mt-2'>Please note that refunds will not be granted in cases where:</p>
                    <p><span className='font-semibold text-gray-900'>-</span>{" The dissatisfaction is due to personal preference or change of mind after the service has been completed."}</p>
                    <p><span className='font-semibold text-gray-900'>-</span>{" The service was used in violation of our terms and conditions."}</p>

                </div>

                <h2 className="text-2xl font-semibold mb-4">Refund Process</h2>
                <div className="text-gray-700 mb-8 ml-4">
                    <p className='mb-2'>{"To initiate a refund request, please follow these steps:"}</p>
                    <p><span className='font-semibold text-gray-900'>1-</span>{" Contact our customer service team at "}<span className='text-blue-500 font-semibold'>support@surfheim.com</span></p>
                    <p><span className='font-semibold text-gray-900'>2-</span>{" Provide your order details and explain the reason for your refund request."}</p>
                    <p><span className='font-semibold text-gray-900'>3-</span>{" Our team will review your request and respond within 5 business days."}</p>
                    <p className='mt-2'>{"If approved, refunds will be processed back to the original payment method used during the purchase within 7-10 business days."}</p>
                </div>

                <h2 className="text-2xl font-semibold mb-4">Conclusion</h2>
                <div className="text-gray-700 ml-4">
                    {"We value your satisfaction and strive to ensure that our services meet your needs. If you have any questions about this policy or need assistance with a refund request, please do not hesitate to reach out to us."}
                </div>
            </div>

            <Footer />
        </div>
    )
};

export default Refund;
