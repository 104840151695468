import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';


const UserGuide = () => {

  return (
    <div>
        <Helmet>
            <title>VPN User Guide | Surfheim VPN - Setup & Usage Instructions</title>
            <link rel="canonical" href="https://www.surfheim.com/user-guide" />
            <meta name="description" content="Explore the Surfheim VPN user guide for detailed setup and usage instructions. Learn how to install and configure Surfheim VPN on various devices for optimal online privacy and security." />
            <meta name="keywords" content="VPN user guide, Surfheim VPN instructions, setup VPN, VPN installation, online privacy, secure browsing, VPN for Windows, VPN for macOS, VPN for Android, VPN for iOS" />
            <meta property="og:title" content="Surfheim VPN User Guide - Setup & Usage Instructions" />
            <meta property="og:description" content="Get comprehensive instructions on how to set up and use Surfheim VPN on different devices. Ensure your online privacy and security with our easy-to-follow guide." />
            <meta property="og:image" content="%PUBLIC_URL%/user-guide-image.png" />
            <meta property="og:url" content="https://www.surfheim.com/user-guide" />
            <meta property="og:type" content="website" />
        </Helmet>

        <Header />
        <div className="md:container bg-light/25 mx-8 md:mx-auto px-4 py-8 rounded-xl my-8 text-justify font-secondary">
            <h1 className="text-2xl md:text-3xl font-bold mb-4">Surfheim VPN User Guide</h1>
            <h2 className="mb-4 text-xl">
                This guide will help you set up and use Surfheim VPN on mobile or other devices.
            </h2>

            <section className="my-8">
                <h2 className="text-2xl font-semibold mb-4">Subscription link</h2>
                <p className="mb-4">
                    When you purchase a VPN, a special link is created just for you. This link allows you to view important information about your subscription, including data usage and remaining days.
                </p>
                <h2 className="text-2xl font-semibold mb-4">How to Access Your Subscription Link</h2>
                <ol className="list-decimal list-inside mb-4">
                <li className='pt-2'>Log in to your Surfheim account and open your <a href='/Profile/' className='text-blue-500 underline'>profile</a> page</li>
                <li className='pt-2'>In your profile, under subscription section look for "Subscription Link" and click on the link to copy it to your clipboard
                    In addition there is a QR code that you can scan with your smartphone to access your subscription information quickly.
                </li>
                <li className='pt-2'>Open a Web Browser and then in the address bar, "Paste" link and hit the Enter key to see your subscription details.</li>
                </ol>
            </section>

            <section className="my-8">
                <h2 className="text-2xl font-semibold mb-4">Connecting to the VPN</h2>
                <p className="mb-4">
                    {"You can also use your subscription link to connect to our VPN through our applications. Here’s how:"}
                </p>
                
                <ol className="list-decimal list-inside mb-4 ">
                    <li className='pt-2'>Download and install the VPN app suitable for your device. You can find apps in the <a href='/Download/' className='text-blue-500 underline'>download</a> page</li>
                    <li className='pt-2'>Launch the VPN app on your device. Look for the option to Add Subscription. You may also have the option to Import from Clipboard or Scan from QR Code. Choose the method that works best for you:</li>
                    <li className='pt-2'>Once your subscription is added, simply hit the Connect button to establish a secure VPN connection.</li>
                </ol>
            </section>

            <section className="my-8">
                <h2 className="text-2xl font-semibold mb-4">Need Help?</h2>
                <p className="mb-4">
                    If you have any questions or need assistance, feel free to contact our support team by submitting a ticket or emailing us at <span className='text-blue-500'>support@surfheim.com</span>. We’re here to help you enjoy a secure and private internet experience!
                </p>

                <p className="mb-4">
                    Thank you for choosing Surfheim VPN service!
                </p>
                
            </section>
        </div>

        <Footer />
    </div>
  );
};

export default UserGuide;

